import React from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, FunctionsBrowser, SEO } from "components";

const DocumentationBrowser = ({ location }) => {
  const title = "ECL Documentation";
  const description = "Emerald Cloud Lab Documentation Browser";

  return (
    <DocPageLayout headerText="Documentation Center">
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <FunctionsBrowser />
    </DocPageLayout>
  );
};

export default DocumentationBrowser;
